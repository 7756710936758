<template>
  <div class="container">
    <div>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content">
            <div style="display: flex; margin-bottom: 10px;">
              <img src="../../assets/up.png" alt class="icon-img" />
              <span class="col-span">上架京东商品总数</span>
            </div>
            <span
              @click="goGoodsList('4')"
              class="col-span"
              style="font-size: 34px; color: #EF345A; cursor: pointer;"
            >{{statistics.up_goods}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div style="display: flex; margin-bottom: 10px;">
              <img src="../../assets/down.png" alt class="icon-img" />
              <span class="col-span">下架京东商品总数</span>
            </div>
            <span
              @click="goGoodsList('3')"
              class="col-span"
              style="font-size: 34px; color: #EF345A; cursor: pointer;"
            >{{statistics.down_goods}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div style="display: flex; margin-bottom: 10px;">
              <img src="../../assets/under.png" alt class="icon-img" />
              <span class="col-span">
                价格亏本京东商品总数
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="会员价 ＞ 分销价 或 会员价 ＞ 销售价"
                  placement="top-start"
                >
                  <img src="../../assets/question.png" alt style="width: 16px; height: 16px;" />
                </el-tooltip>
              </span>
            </div>
            <router-link to="/shop-goods/shop-goods-list?type=2" tag="div" style="cursor: pointer;">
              <span
                @click="goGoodsList('1')"
                class="col-span"
                style="font-size: 34px; color: #EF345A; cursor: pointer;"
              >{{statistics.lose_goods}}</span>
            </router-link>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content">
            <div style="display: flex; margin-bottom: 10px;">
              <img src="../../assets/error.png" alt class="icon-img" />
              <span class="col-span">
                市场价异常京东商品总数
                <el-tooltip class="item" effect="dark" content="市场价 ＜ 销售价" placement="top-start">
                  <img src="../../assets/question.png" alt style="width: 16px; height: 16px;" />
                </el-tooltip>
              </span>
            </div>
            <span
              @click="goGoodsList('2')"
              class="col-span"
              style="font-size: 34px; color: #EF345A; cursor: pointer;"
            >{{statistics.mktprice_anomaly_goods}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div style="display: flex; margin-bottom: 10px;">
              <img src="../../assets/down1.png" alt class="icon-img" />
              <span class="col-span">昨日自动下架京东商品总数</span>
            </div>
            <span
              class="col-span"
              style="font-size: 34px; color: #8B52D8;"
            >{{statistics.auto_down_goods}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div style="display: flex; margin-bottom: 10px;">
              <img src="../../assets/up1.png" alt class="icon-img" />
              <span class="col-span">昨日自动上架京东商品总数</span>
            </div>
            <span
              class="col-span"
              style="font-size: 34px; color: #FD6435;"
            >{{statistics.auto_up_goods}}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="height: 10px;"></div>
    <!-- 京东商品自动上下架设置   -->
    <el-card>
      <div slot="header">
        <span style="font-weight: bold; font-size: 17px;">京东商品自动上下架设置</span>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <el-card style="width: 49%;" class="card-header">
          <div slot="header">
            <span style="font-size: 13px; color: #333333; font-weight: bold;">下架规则</span>
            <el-button
              style="float: right; padding: 3px 0; font-size: 13px;"
              type="text"
              @click="openDownDialog('down')"
            >规则设置</el-button>
          </div>
          <div class="card-body">
            <div class="card-div">
              <span
                class="card-span"
              >1、利润条件：利润率（销价） ≤ {{ downData[0].param[0] ? downData[0].param[0] : '?' }} %</span>
              <span class="card-span" v-if="checked[0]" style="color: #0030ff;">已设置</span>
              <span class="card-span" v-else style="color: #999999;">未设置</span>
            </div>
            <div class="card-div">
              <span class="card-span">2、京东市场价：市场价 &lt; 销售价</span>
              <span class="card-span" v-if="checked[1]" style="color: #0030ff;">已设置</span>
              <span class="card-span" v-else style="color: #999999;">未设置</span>
            </div>
            <div class="card-div">
              <span class="card-span">3、亏本条件：会员价 &gt; 分销价 或 会员价 &gt; 销售价</span>
              <span class="card-span" v-if="checked[2]" style="color: #0030ff;">已设置</span>
              <span class="card-span" v-else style="color: #999999;">未设置</span>
            </div>
          </div>
        </el-card>
        <el-card style="width: 49%;" class="card-header">
          <div slot="header">
            <span style="font-size: 13px; color: #333333; font-weight: bold;">上架规则</span>
            <el-button
              style="float: right; padding: 3px 0; font-size: 13px;"
              type="text"
              @click="openDownDialog('up')"
            >规则设置</el-button>
          </div>
          <div class="card-body">
            <div class="card-div">
              <span
                class="card-span"
              >1、利润条件：毛利率 ≥ {{ upData[0].param[0] ? upData[0].param[0] : '?' }} %</span>
              <span class="card-span" v-if="checked[3]" style="color: #0030ff;">已设置</span>
              <span class="card-span" v-else style="color: #999999;">未设置</span>
            </div>
          </div>
        </el-card>
      </div>
    </el-card>
    <div style="height: 10px;"></div>
    <!-- 京东商品信息-->
    <el-card>
      <div slot="header">
        <span style="font-size: 17px; font-weight: bold; padding: 10px 0;">京东商品信息</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="-"
          value-format="yyyyMMdd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          style="float: right; width: 260px;"
          @change="handleDateChange"
        ></el-date-picker>
      </div>
      <en-table-layout :tableData="operationList" :loading="loading">
        <template slot="table-columns">
          <el-table-column label="日期">
            <template slot-scope="{row}">
              <div>{{ formatting(row.vs_date) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="up_goods" label="上架商品"></el-table-column>
          <el-table-column prop="down_goods" label="下架商品"></el-table-column>
          <el-table-column prop="lose_goods">
            <template slot="header">
              <el-tooltip effect="dark" content="会员价 ＞ 分销价 或 会员价 ＞ 销售价" placement="top">
                <label class="cursor-pointer">
                  价格亏本商品
                  <img src="../../assets/question.png" alt style="width: 16px; height: 16px;" />
                </label>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="mktprice_anomaly_goods">
            <template slot="header">
              <el-tooltip effect="dark" content="市场价 ＜ 销售价" placement="top">
                <label class="cursor-pointer">
                  市场价异常商品
                  <img
                    src="../../assets/question.png"
                    alt
                    style="width: 16px; height: 16px;"
                  />
                </label>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="auto_down_goods" label="自动下架商品"></el-table-column>
          <el-table-column prop="auto_up_goods" label="自动上架商品"></el-table-column>
        </template>
        <el-pagination
          slot="pagination"
          @size-change="handlePageSizeChange"
          @current-change="handlePageCurrentChange"
          :current-page="optionsParams.page"
          :page-size="optionsParams.size"
          :total="optionsParams.data_total"
          :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout"
          background
        ></el-pagination>
      </en-table-layout>
    </el-card>
    <x-dialog :proxy="downDialogProxy">
      <div class="XJSC_znyy-rulesSetting">
        <el-checkbox v-model="downData[0].checked" @change="apply">
          1、利润条件：利润率（销价） ≤
          <el-input
            size="small"
            :disabled="!downData[0].checked"
            @input="handleInput2"
            @focus="hintShow=true"
            @blur="hintShow=false"
            v-model="num"
            :min="0"
            style="width: 80px;"
          ></el-input>%
        </el-checkbox>
        <div>
          <el-radio
            :disabled="!downData[0].checked"
            @change="apply"
            v-model="downData[0].rule_status"
            :label="0"
          >自动下架</el-radio>
          <el-radio
            :disabled="!downData[0].checked"
            @change="apply"
            v-model="downData[0].rule_status"
            :label="1"
          >自动下架并移除</el-radio>
        </div>
        <div v-if="hintShow" class="hint">请输入最多保留到小数点后两位的正数。</div>
      </div>
      <div class="XJSC_znyy-rulesSetting">
        <el-checkbox v-model="downData[1].checked" @change="apply">2、京东市场价：市场价 &lt; 销售价</el-checkbox>
        <div>
          <el-radio
            :disabled="!downData[1].checked"
            @change="apply"
            v-model="downData[1].rule_status"
            :label="0"
          >自动下架</el-radio>
          <el-radio
            :disabled="!downData[1].checked"
            @change="apply"
            v-model="downData[1].rule_status"
            :label="1"
          >自动下架并移除</el-radio>
        </div>
      </div>
      <div class="XJSC_znyy-rulesSetting">
        <el-checkbox
          v-model="downData[2].checked"
          @change="apply"
        >3、亏本条件：会员价 &gt; 分销价 或 会员价 &gt; 销售价</el-checkbox>
        <div>
          <el-radio
            :disabled="!downData[2].checked"
            @change="apply"
            v-model="downData[2].rule_status"
            :label="0"
          >自动下架</el-radio>
          <el-radio
            :disabled="!downData[2].checked"
            @change="apply"
            v-model="downData[2].rule_status"
            :label="1"
          >自动下架并移除</el-radio>
        </div>
      </div>

      <span style="display: block; margin: 15px 0; color: #8c939d;">提示：设置自动下架的利润率（销价）值需小于设置自动上架的利润率（销价）值</span>
    </x-dialog>
    <x-dialog :proxy="upDialogProxy">
      <div class="XJSC_znyy-rulesSetting">
        <el-checkbox v-model="upData[0].checked" @change="apply">
          1、利润条件：毛利率 ≥
          <el-input
            size="small"
            @input="handleInput2"
            @focus="hintShow=true"
            @blur="hintShow=false"
            :disabled="!upData[0].checked"
            :min="0"
            v-model="num"
            style="width: 80px;"
          ></el-input>%
        </el-checkbox>
        <div v-if="hintShow" class="hint">请输入最多保留到小数点后两位的正数。</div>
      </div>
      <span style="display: block; margin: 15px 0; color: #8c939d;">提示：设置自动上架的毛利率值需大于设置自动下架的毛利率值</span>
    </x-dialog>
  </div>
</template>

<script>
import XDialog from "@/components/x-dialog/x-dialog.vue";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_goods from "@/api/goods";
import * as API_login from "@/api/login";

export default {
  name: "intelOperation",
  components: {
    XDialog,
    EnTableLayout
  },
  data() {
    return {
      hintShow: false,
      shop_id: JSON.parse(localStorage.getItem("shopInfo2021")).shop_id,
      num: "",
      operationList: [],
      statistics: {},
      loading: false,
      checked: [false, false, false, false],
      downData: [
        {
          checked: false,
          param: [""],
          rule: "ENTERPRISE_PROFIT",
          rule_status: 0
        },
        {
          checked: false,
          param: [],
          rule: "MKTPRICE",
          rule_status: 0
        },
        {
          checked: false,
          param: [],
          rule: "LOSS_MONEY",
          rule_status: 0
        }
      ],
      upData: [
        {
          checked: false,
          param: [""],
          rule: "ENTERPRISE_PROFIT",
          rule_status: "0"
        }
      ],
      downDialogProxy: $xDialog.create({
        title: "规则设置",
        wrapperClass: "supplier-goods-selector",
        disableConfirm: false,
        disableCancel: false,
        beforeConfirm: () => this.dialogConfirm("down"),
        beforeCancel: () => this.dialogClose(),
        beforeClose: () => this.dialogClose(),
        width: "50vw"
      }),
      downFormData: {
        id: 1,
        fit: ""
      },
      upDialogProxy: $xDialog.create({
        title: "规则设置",
        wrapperClass: "supplier-goods-selector",
        disableConfirm: false,
        disableCancel: false,
        beforeConfirm: () => this.dialogConfirm("up"),
        beforeCancel: () => this.dialogClose(),
        beforeClose: () => this.dialogClose(),
        width: "50vw"
      }),
      upFormData: {
        id: 1,
        fit: ""
      },
      optionsParams: {
        start: "",
        end: "",
        page: 1,
        size: 10,
        data_total: 0
      },
      // 以下是京东商品信息列表
      value1: "",
      pickerOptions: {
        disabledDate: time => {
          return time.getTime() >= new Date().getTime() - 86400000;
        }
      }
    };
  },
  methods: {
    goGoodsList(type) {
      API_login.getAutoUpDownStatus().then(resStatus => {
        if (resStatus.data === 0 || !resStatus.data) {
          this.$message.error("该功能已被禁用，即将跳转至首页");
          const timer = setTimeout(() => {
            this.$router.go(0);
          }, 1000);
        } else {
          sessionStorage.setItem('shopGoodsListType', type)
          this.$router.push(`/shop-goods/shop-goods-list`);
        }
      });
    },
    dialogClose() {
      console.log(this.upData[0].param[0], this.upData[0].checked);
      if (!this.upData[0].param[0] && this.upData[0].checked) {
        this.downData[0].checked = false;
      }
      if (!this.downData[0].param[0] && this.downData[0].checked) {
        this.downData[0].checked = false;
      }
      if (this.downData[1].checked) {
        this.downData[1].checked = false;
      }
      if (this.downData[2].checked) {
        this.downData[2].checked = false;
      }
    },
    handleDateChange(val) {
      console.log(val);
      if (val) {
        this.optionsParams.start = val[0];
        this.optionsParams.end = val[1];
      } else {
        this.optionsParams.start = "";
        this.optionsParams.end = "";
      }
      this.getOperationList();
    },
    formatting(date) {
      date = String(date);
      return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
        6,
        8
      )}`;
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.optionsParams.size = size;
      this.getOperationList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.optionsParams.page = page;
      this.getOperationList();
    },
    getOperationList() {
      console.log(this.pickerOptions, this.value1);
      API_goods.getOperationList(this.optionsParams).then(res => {
        console.log(res);
        this.operationList = res.data;
        this.optionsParams.data_total = res.data_total;
      });
    },
    handleInput2(e) {
      // 通过正则过滤小数点后两位
      this.num = e.match(/^\d*(\.?\d{0,2})/g)[0];
    },
    apply() {
      this.$forceUpdate();
    },
    // 打开下架弹窗
    openDownDialog(type) {
      if (type === "up") {
        this.num = this.upData[0].param[0];
        this.upData[0].checked = this.checked[3];
        this.upDialogProxy.display();
      } else {
        this.num = this.downData[0].param[0];
        this.downData[0].checked = this.checked[0];
        this.downData[1].checked = this.checked[1];
        this.downData[2].checked = this.checked[2];
        this.downDialogProxy.display();
      }
      this.apply();
    },
    getAutoUpDown() {
      API_goods.getAutoUpDown().then(res => {
        if (JSON.parse(res.data.up_rule)) {
          JSON.parse(res.data.up_rule).forEach(elem => {
            this.upData = [];
            let cc = {};
            switch (elem.rule) {
              case "ENTERPRISE_PROFIT":
                this.upData[0] = { ...elem, checked: true };
                break;
              default:
                break;
            }
          });
        }
        if (JSON.parse(res.data.down_rule)) {
          JSON.parse(res.data.down_rule).forEach(elem => {
            switch (elem.rule) {
              case "ENTERPRISE_PROFIT":
                this.downData[0] = { ...elem, checked: true };
                break;
              case "MKTPRICE":
                this.downData[1] = { ...elem, checked: true };
                break;
              case "LOSS_MONEY":
                this.downData[2] = { ...elem, checked: true };
                break;
              default:
                break;
            }
          });
        }
        this.checked[3] = this.upData[0].checked;
        this.checked[0] = this.downData[0].checked;
        this.checked[1] = this.downData[1].checked;
        this.checked[2] = this.downData[2].checked;
        this.apply();
      });
    },
    dialogConfirm(type) {
      let up = [];
      let down = [];
      console.log(this.upData, this.downData);
      if (type === "up") {
        this.upData[0].param[0] = this.num;
      } else {
        this.downData[0].param[0] = this.num;
      }
      if (
        this.upData[0].param[0] &&
        this.upData[0].checked &&
        this.downData[0].param[0] &&
        this.downData[0].checked
      ) {
        console.log(
          Number(this.upData[0].param[0]) <= Number(this.downData[0].param[0])
        );
        console.log(this.upData[0].param[0], this.downData[0].param[0]);
        // if (
        //   Number(this.upData[0].param[0]) <= Number(this.downData[0].param[0])
        // ) {
        //   if (type === "up") {
        //     this.$message.error(
        //       "设置自动上架的毛利率值需大于自动下架的毛利率值"
        //     );
        //   } else {
        //     this.$message.error(
        //       "设置自动下架的毛利率值需小于自动上架的毛利率值"
        //     );
        //   }
        //   return false;
        // }
      }
      if (type === "up") {
        this.upData.forEach(elem => {
          if (elem.checked) {
            up.push({
              param: elem.param,
              rule: elem.rule,
              rule_status: elem.rule_status
            });
          }
        });
        if (!this.upData[0].param[0] && this.upData[0].checked) {
          this.$message.error("输入框不能为空");
          return false;
        } else {
          API_login.getAutoUpDownStatus().then(resStatus => {
            if (resStatus.data === 0 || !resStatus.data) {
              this.$message.error("该功能已被禁用，即将跳转至首页");
              const timer = setTimeout(() => {
                this.$router.go(0);
              }, 1000);
              return false;
            } else {
              API_goods.saveAutoUpDown({ state: 0 }, up).then(res => {
                if (res.code === 200) {
                  this.$message.success(res.message);
                  this.getAutoUpDown();
                }
              });
              this.upDialogProxy.dismiss();
            }
          });
        }
      } else {
        this.downData.forEach(elem => {
          if (elem.checked) {
            down.push({
              param: elem.param,
              rule: elem.rule,
              rule_status: elem.rule_status
            });
          }
        });
        if (!this.downData[0].param[0] && this.downData[0].checked) {
          this.$message.error("输入框不能为空");
          return false;
        } else {
          API_login.getAutoUpDownStatus().then(resStatus => {
            if (resStatus.data === 0 || !resStatus.data) {
              this.$message.error("该功能已被禁用，即将跳转至首页");
              const timer = setTimeout(() => {
                this.$router.go(0);
              }, 1000);
              return false;
            } else {
              API_goods.saveAutoUpDown({ state: 1 }, down).then(res => {
                if (res.code === 200) {
                  this.$message.success(res.message);
                  this.getAutoUpDown();
                }
              });
              this.downDialogProxy.dismiss();
            }
          });
        }
      }
    }
  },
  created() {
    this.getAutoUpDown();
    this.getOperationList();
    API_goods.statistics().then(res => {
      this.statistics = res;
    });
  }
};
</script>

<style scoped lang="scss">
/deep/ .card-header {
  .el-card__header {
    padding: 10px 20px;
    background-color: #e6ecf7;
  }
}
/deep/ .el-card__header {
  padding: 10px 20px;
  height: 60px;
  line-height: 40px;
}
.XJSC_znyy-rulesSetting {
  position: relative;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  line-height: 50px;
  .hint {
    position: absolute;
    bottom: 0px;
    left: 50px;
    line-height: 12px;
    font-size: 12px;
    color: red;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background-color: #ffffff;
  padding: 20px 25px;
}
.col-span {
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #393c41;
}
.icon {
  font-size: 48px;
  margin-right: 4px;
  color: #1abc9c;
}
.icon-img {
  width: 40px;
  height: 40px;
  margin-right: 17px;
}
.card-body {
  padding: 10px 40px;
  .card-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .card-span {
      display: block;
      color: #333333;
    }
  }
}
</style>
